<template>
  <Breadcrumbs title="" main="Pages" />
  <div class="container-fluid">
    <div class="faq-wrap">
      <div class="row">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <form @submit.prevent="findOrder">
            <div class="card">
              <div class="card-body">
                <div class="media">
                  <!-- <vue-feather class="m-r-30" type="search"></vue-feather> -->
                  <div class="media-body">
                    <h6 class="f-w-500"> </h6>
                    <div class="row">

                      <div class="col-sm-12">
                        <b>{{this.currentLanguage ==='en'?'Find order application':'ตรวจสอบรายชื่อผู้สมัคร'}}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="filter_type" checked required type="radio"
                          name="inlineRadioOptions" id="inlineRadio1" value="mobile_no">
                        <label class="form-check-label" for="inlineRadio1">
                          หมายเลขโทรศัพท์
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input class="form-check-input" v-model="filter_type" type="radio" required name="inlineRadioOptions"
                          id="inlineRadio2" value="fname">
                        <label class="form-check-label" for="inlineRadio2">
                          ชื่อผู้สมัคร
                        </label>
                      </div>
                    </div>
                      <div class="mb-12 col-sm-12">
                        <input
                          required
                          maxlength="50"
                          minlength="4"
                          type="text"
                          class="form-control"
                          :placeholder="this.filter_type==='mobile_no'?'หมายเลขโทรศัพท์':'ชื่อผู้สมัคร'"
                          v-model="txt_search"
                        />
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="row">&nbsp;</div>
                <div class="row">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      style="cursor: pointer"
                      class="btn btn-primary btn-lg"
                    >
                    <vue-feather type="loader" size="20" animation="spin" v-if="this.is_searching ==='true'"></vue-feather> {{this.currentLanguage ==='en'?'Search':'ค้นหา'}}
                    </button>
                    &nbsp;
                    <button
                      style="cursor: pointer"
                      @click.prevent="clear_result"
                      type="reset"
                      class="btn btn-secondary btn-lg"
                    >
                      {{this.currentLanguage ==='en'?'Cancel':'ยกเลิก'}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-sm-1"></div>
      </div>

      <div class="row" v-if="this.start_search === 'true'">
        <div class="col-sm-1"></div>
        <div class="col-sm-10">
          <div class="card">
            <div class="card-body">
              <div v-if="this.is_searching === 'true'">
                <div class="d-flex justify-content-center">
                  <img width="60" src="../../assets/images/running_loading.svg" />
                </div>
              </div>

              <div
                v-if="!this.resultList.length && this.is_searching === 'false'"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{this.currentLanguage ==='en'?'Registration date':'วันที่สั่งซื้อ'}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?'Status':'สถานะ'}}</th>
                      <th scope="col">{{this.currentLanguage ==='en'?'View application':'ดูคำสั่งซื้อ'}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="10" style="text-align: center">
                        {{this.currentLanguage ==='en'?'Application not found':'ไม่พบข้อมูลคำสั่งซื้อ'}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div v-if="this.resultList.length">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">{{this.currentLanguage ==='en'?'Order':'คำสั่งซื้อ'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'Name':'ชื่อ-สกุล'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'age':'อายุ'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'Mobile phone':'หมายเลขโทรศัพท์'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'Type':'ประเภทการสมัคร'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'Model name':'ประเภทการเเข่งขัน'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'T-shirt':'ขนาดเสื้อ'}}</th>
                        <th scope="col">{{this.currentLanguage ==='en'?'BIB_NO':'เลขบิบ'}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(src, index) in this.resultList" :key="index">
                        <td><a target="_blank" :href=" '/order/' + src.order_uuid"><u><b>{{ src.order_uuid }}</b></u></a></td>
                        <td>{{ src.fname }} {{ src.lname }}</td>
                        <td>{{ src.age }}</td>
                        <td>{{ src.mobile_no }}</td>
                        <td>{{ src.run_type_name }}</td>
                        <td>{{ src.run_model_name }}</td>
                        <td>{{ src.t_shirt_name }}</td>
                        <td>{{ src.bib_no }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1"></div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { useHead } from '@vueuse/head'
import {computed, reactive } from 'vue'
export default {
  data() {
    return {
      email: "",
      txt_search: "",
      resultList: {},
      status: "",
      is_searching: "false",
      start_search: "false",
      currentLanguage: 'th',
      filter_type: "mobile_no"
    };
  },
  setup() {
    useHead({
      title: computed(() => `ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH`),
      meta: [
        {
          name: `description`,
          content: computed(() => `'งานวิ่ง ระบบรับสมัครงานวิ่ง | RUNRACE.IN.TH'`),
        },
        {
          name: `keywords`,
          content: computed(() => `งานวิ่ง,ระบบรับสมัครงานวิ่ง,ค้นหาคำสั่งซื้อ,ค้นหารูปวิ่ง, หารูปวิ่ง,งานวิ่ง,runrace,runninghub,facesearch`),
        }
        ],
     
    })
  },
  async created(){
    this.currentLanguage = localStorage.getItem('currentLanguage');
  },
  methods: {
    async findOrder() {

    
      
      this.start_search = "true";
      this.resultList = {};
      this.is_searching = "true";

      
      const data = {
        tk: process.env.VUE_APP_FN_TO_BK_SECRET_KEY,
        event_key: this.$route.params.event_key,
        txt_search: this.txt_search,
        filter_type: this.filter_type
      };
      let result = await axios.post("/v1/order/event-check", data);
      this.status = result.data.status;
      if (result.data.status === "success") {
        this.resultList = result.data.body;
      }
      this.is_searching = "false";
    },
    async clear_result() {
      this.start_search = "false";
      this.resultList = {};
      this.txt_search = "";
    },
  },
};
</script>